import { Ifo } from './types'

const ifos: Ifo[] = [
  {
    id: 'bafi',
    address: '0xa618aB37417C3E0e7Fc0D78EBA9487165a76840f', // ido contract
    tokenContract: '0xB20b17d86F868B9C2A1e6201bA881D68c1325dE4',
    isActive: false,
    name: 'BAFI (Bafiswap)',
    subTitle: 'Bafi Swap is a Binance-based decentralized exchange protocol.',
    description:
      'Bafi Swap aims to be a user-friendly exchange with high gas performance, censorship resistance, and zero rent extraction. It is beneficial to traders and works especially well as a module of other smart contracts that need on-chain liquidity assurance.',
    launchDate: 'April. 22',
    launchTime: '9PM UTC',
    saleAmount: '3,500 BAFI',
    raiseAmount: '$3,000,000',
    cakeToBurn: '',
    projectSiteUrl: 'https://bafi.finance/',
    currency: 'BNB',
    currencyAddress: '',
    currencyDecimals: 18,
    tokenDecimals: 18,
    tokenSymbol: 'BAFI',
    releaseBlockNumber: 0,
    campaignId: '',
  },
  {
    id: 'bpick',
    address: '0xa618aB37417C3E0e7Fc0D78EBA9487165a76840f', // ido contract
    tokenContract: '0x1a6906a98Ad2f018260aa7C05E6288f8beF6a1cc',
    isActive: false,
    name: 'BPICK (BPICK)',
    subTitle: 'BSCPICKS is a decentralized hybrid platform designed for user engagements in Gaming, NFT, IDO and for advertising of AMA and marketing promotions.',
    description:
      'BSCPICKS is a decentralized hybrid platform designed for user engagements in Gaming, NFT, IDO and for advertising of AMA and marketing promotions.',
    launchDate: '17th August 2021',
    launchTime: '9AM UTC',
    saleAmount: '1,050,000 BPICK',
    raiseAmount: '$24,500',
    cakeToBurn: '',
    projectSiteUrl: 'http://bscpicks.finance/',
    currency: 'BNB',
    currencyAddress: '',
    currencyDecimals: 18,
    tokenDecimals: 18,
    tokenSymbol: 'BPICK',
    releaseBlockNumber: 0,
    campaignId: '',
  },
  {
    id: 'sampandainu',
    address: '0xD512Af44738f5e6b5020D679b0cB1b149c036c40', // ido contract
    tokenContract: '0x33e0d8f94c5b423d99d9a789f7b798d0b9ce8933',
    isActive: false,
    name: 'SAMPADA INU',
    subTitle: 'Sampada Inu is a Asia first meta meme and gamefi token on binance smart chain.',
    description:
      'Sampada Inu is a Asia first meta meme and gamefi token on binance smart chain. Our objective is to create a comprehensive platform of digital monsters that will allow millions of people to participate in the NFT and blockchain-based gaming world in a simple, creative, and exciting way, inspired by Pokemon Story.',
    launchDate: '26th Nov. 2021',
    launchTime: '3:00PM UTC',
    saleAmount: '400,000,000,000 SAMPADA',
    raiseAmount: '$100,000',
    cakeToBurn: '',
    projectSiteUrl: 'https://sampadainu.com/',
    currency: 'BNB',
    currencyAddress: '',
    currencyDecimals: 18,
    tokenDecimals: 18,
    tokenSymbol: 'SAMPADA',
    releaseBlockNumber: 0,
    campaignId: '',
  },
  {
    id: 'doecoin',
    address: '0xD512Af44738f5e6b5020D679b0cB1b149c036c40', // ido contract
    tokenContract: '0x5fCEB9D4D7b48515d661aa9ce23bE8f890e0E550',
    isActive: true,
    name: 'DOE FOUNDATION',
    subTitle: 'DoeCoin is managed by the DOE Foundation. It is not an overseer, but rather the foundation upon which DoeCoin and BAFI holders in the DoeCoin DAO can construct.',
    description:
      'DoeCoin is managed by the DOE Foundation. It is not an overseer, but rather the foundation upon which DoeCoin and BAFI holders in the DoeCoin DAO can construct. DOE is a loose clone of Apecoin that is significantly more powerful. Doe is spontaneously faster than Ape, which accounts for the increased speed.',
    launchDate: '14th April. 2022',
    launchTime: '6pm:00PM UTC',
    saleAmount: '200,000,000 DOE',
    raiseAmount: '$100,000',
    cakeToBurn: '',
    projectSiteUrl: 'http://doecoin.network/',
    currency: 'BNB',
    currencyAddress: '',
    currencyDecimals: 18,
    tokenDecimals: 18,
    tokenSymbol: 'DOE',
    releaseBlockNumber: 0,
    campaignId: '',
  },
  // {
  //   id: 'berry',
  //   address: '0x5d028cE3435B2bB9AceBfaC599EEbA1ccD63d7dd',
  //   isActive: false,
  //   name: 'Berry (BRY)',
  //   subTitle: 'A decentralized oracle network on BSC',
  //   description:
  //     'Berry Data is a transparent community-veriﬁed price oracle on BSC. Berry Data provides a trustless and decentralized alternative for off-chain data. As the governance token for Berry Data, BRY allows holders to pay for Berry Data feeds, vote on governance issues, validate data through staking, and build the Berry ecosystem.',
  //   launchDate: 'Feb. 16',
  //   launchTime: '5PM SGT',
  //   saleAmount: '2,000,000 BRY',
  //   raiseAmount: '$1,000,000',
  //   cakeToBurn: '$500,000',
  //   projectSiteUrl: 'https://berrydata.co/',
  //   currency: 'CAKE-BNB LP',
  //   currencyAddress: '0xA527a61703D82139F8a06Bc30097cC9CAA2df5A6',
  //   tokenDecimals: 18,
  //   tokenSymbol: 'BRY',
  //   releaseBlockNumber: 4750968,
  //   campaignId: '511060000',
  // },
]

export default ifos
